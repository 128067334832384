import React from "react"
import Link from "shared/components/Link"
import { array, string } from "prop-types"
import { Container, StyledStepper, StepList, Step } from "./styles"

const BreadcrumbStepper = props => {
  return (
    <StepList data-qa="campaign-breadcrumb-stepper">
      <Container flexAround={props.flexAround}>
        {props.steps
          .filter(s => s.visible)
          .map((step, index) => {
            return (
              <StyledStepper
                key={index}
                active={step.isCurrent}
                previous={step.isClickable}
                future={!step.isClickable}>
                <Link
                  to={step.isClickable ? step.link : "#"}
                  style={{ display: "block" }}>
                  <span>
                    <Step previous={step.isClickable} active={step.isCurrent}>
                      {step.title}
                    </Step>
                  </span>
                </Link>
              </StyledStepper>
            )
          })}
      </Container>
    </StepList>
  )
}

BreadcrumbStepper.propTypes = {
  steps: array.isRequired
}

export default BreadcrumbStepper
