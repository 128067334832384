import { lightGray } from "../../utils/colors"
import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"
import { md } from "mill/utils/breakpoints"

export const StyledStepper = styled.div`
  a {
    text-align: center;
    &:hover {
      cursor: ${props => (props.future ? "not-allowed" : "pointer")};
    }
  }

  ${props =>
    props.active &&
    `
    a:after {
      content: "";
      background: ${lightGray};
      height: 4px;
      width: 1000vh;
      position: absolute;;
      bottom: 2rem;
    }
  `};
`

export const Container = styled.div`
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3rem;
  display: flex;
  justify-content: ${props =>
    props.flexAround ? "space-around" : "space-between"};
`

export const StepList = styled.div`
  overflow: hidden;
  position: relative;
  height: 9rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: #ffffff;
  display: none;

  &:before {
    content: "";
    position: absolute;
    bottom: 2rem;
    width: 100%;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.colors.primary};
  }

  @media ${md} {
    display: block;
  }
`

export const Step = styled.span`
  display: block;
  flex: 1;
  position: relative;
  font-family: Raleway;
  font-size: 1.6rem;
  color: ${props => (props.active ? props.theme.colors.primary : "#727272")};
  transition: all 0.1s ease-out;

  &:hover {
    color: ${props =>
      props.previous ? props.theme.colors.primary : "#727272"};
    transition: all 0.1s ease-in;
    &:after {
      transition: all 0.1s ease-in;
      background-color: ${props =>
        props.previous ? props.theme.colors.secondary : lightGray};
    }
  }

  &:after {
    transition: all 0.1s ease-out;
    bottom: -2.7rem;
    left: 50%;
    margin-left: -0.9rem;
    position: absolute;
    content: "";
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 1.8rem;
    z-index: ${zIndex.base + 1};
    background-color: ${props =>
      props.previous ? props.theme.colors.primary : lightGray};
  }
`
